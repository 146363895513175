.Search_data_dv {
  width: 84%;
  background: #fff;
  position: absolute;
  left: 8%;
  border-radius: 20px;
  border: 1px solid #ccc;
  z-index: 999;
}

.Search_data_dv .Search_data_dv_inner {
  padding: 20px;
}
.Search_data_dv table tr.common_top_cnt td {
  color: #1a4c7a;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 18px 10px;
}
.Search_data_dv table thead tr {
  width: 100%;
}
.Search_data_dv table thead {
  width: 100%;
  border-radius: 8px;
}
.Search_data_dv table thead.full_width_dv {
  width: 100%;
  background: #0284C7;
  border-bottom: solid;
  border-radius: 8px;
  color: #f8f5f5;
}
.Search_data_dv table thead.full_width_dv tr th {
  width: 20%;
  text-align: left;
  padding: 13px 10px;
}
.Search_data_dv table tbody.full_width_dv tr td {
  width: 20%;
  padding: 11px 10px;
  border-radius: 15px !important;
  /* background: #fff; */
  margin: 0px 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Search_data_dv table tbody.full_width_dv tr td svg {
  color: #1a4c7a;
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 4px;
}
.Search_data_dv table tbody.full_width_dv tr td button {
  padding: 2px 8px;
  line-height: 18px;
  float: right;
}

.full_width_dv th:hover {
  behavior: unset;
  border-radius: 8px;
  /* background: #def3ff; */
}
.full_width_dv.ctd tr:hover {
  width: 100% !important;
  background: #def3ff;
  display: flex;
  justify-content: space-between;
  padding: 4px 20px;
}
.full_width_dv tr {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  padding: 4px 20px;
}
.Search_data_dv table {
  width: 100%;
}
.Search_data_dv table tbody.full_width_dv tr td:first-child {
  border-radius: 8px 0px 0px 8px;
}

.Search_data_dv table tbody.full_width_dv tr td:last-child {
  border-radius: 0px 8px 8px 0px;
}
.Search_data_dv table thead.full_width_dv tr th svg {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
  margin-right: 4px;
}
