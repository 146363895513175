.profile_data_main_wrapper {
  width: 100%;
  margin-left: auto;
  display: flex;
  padding-right: 40px;
  margin-top: 100px;
  gap: 2%;
}
.profile_details_wrapper {
    width: 23%;
    padding: 22px;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.profile_data_rgt_dv {
  border-radius: 10px;
  background: #f5f6f8;
}
.profile_data_rgt_dv_tp {
    border-radius: 10px;
    width: 75%;
    padding: 10px;
}
.top_head_dv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin-bottom: 15px;
}
.top_head_dv:nth-child(2){
    padding-top: 32px;
}
.top_head_dv:last-child{
    padding-top: 32px;
}
.body_content_dv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px 6px 0;
  margin-bottom: 25px;
  border-radius: 5px;
  background: #FFF;
  margin-top: 11px;
  /* padding-right: 12px; */
}
.profile_name_box h2 {
    margin: 0px;
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}
.profile_name_box .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  min-width: 0 !important;
}
.img_box {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.img_box img {
    width: 100%;
    margin: 20px auto;
    border-radius: 20px;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
button.pink_bg {
  border-radius: 2px;
  border: 1px solid #fb7f7f;
  background: #ffd6d6;
}
button.gray_bg {
  border-radius: 2px;
  color: #acb0b1 !important;
  border: 1px solid #313535;
  background: #566164;
}
button.yellow_bg {
  border-radius: 2px;
  border: 1px solid #f1a041;
  background: #ffecd6;
}
button.green_bg {
  border-radius: 2px;
  border: 1px solid #aad771;
  background: #edffd6;
}
.body_content_dv button {
  color: #565656;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px !important;
}
.body_content_dv input {
  height: 33px;
}
.body_content_dv span {
  height: 33px;
}
.body_content_dv button:hover {
  background: antiquewhite;
  opacity: 1.1;
}
.body_content_dv span svg {
  height: 33px;
  /* fill: rgba(255, 255, 255, 0.15); */
  stroke-width: 2px;
  stroke: rgba(0, 178, 255, 0.26);
  fill: #8CDCFF;
stroke-width: 2px;
stroke: rgba(0, 178, 255, 0.26);
}
.multiple_box_prfile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
}
.pro_box {
  padding: 12px 18px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 50px;
  border-radius: 20px;
  border: 2px solid #fff;
  background: linear-gradient(135deg, #52e5e7 0%, #130cb7 100%);
  box-shadow: 0px 9px 4px 0px rgba(139, 139, 139, 0.25);
  width: 30%;
}
.left_dtl h4 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  padding: 10px 0px;
}
.left_dtl {
  text-align: left;
}
.right_dtl {
  text-align: left;
}
.top_head_dv button {
  color: #0284c7;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.right_dtl p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: lowercase;
  padding: 12px 0px;
  margin: 0px;
}
.multiple_down_box_profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.multiple_box_prfile .pro_box:first-child {
  background: red;
  border-radius: 20px;
  border: 2px solid #fff;
  background: linear-gradient(135deg, #52e5e7 0%, #130cb7 100%);
  box-shadow: 0px 9px 4px 0px rgba(139, 139, 139, 0.25);
}
.multiple_box_prfile .pro_box:nth-child(2) {
  border-radius: 20px;
  border: 2px solid #fff;
  background: linear-gradient(135deg, #97abff 0%, #123597 100%);
  box-shadow: 0px 9px 4px 0px rgba(139, 139, 139, 0.25);
}
.multiple_box_prfile .pro_box:nth-child(3) {
  border-radius: 20px;
  border: 2px solid rgba(75, 52, 122, 0);
  background: linear-gradient(135deg, #3b2667 0%, #bc78ec 100%);
  box-shadow: 0px 9px 4px 0px rgba(139, 139, 139, 0.25);
}
.multiple_down_box_profile .pro_box:nth-child(1) {
  border-radius: 20px;
  border: 2px solid rgba(255, 167, 118, 0);
  background: linear-gradient(135deg, #ff9d6c 0%, #bb4e75 100%);
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.25);
}
.multiple_down_box_profile .pro_box:nth-child(2) {
  border-radius: 20px;
  border: 2px solid rgba(221, 151, 112, 0);
  background: linear-gradient(135deg, #fec163 0%, #de4313 100%);
}
.multiple_down_box_profile .pro_box:nth-child(3) {
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0);
  background: linear-gradient(135deg, #76e194 0%, #106738 100%);
}
.top_head_dv h3 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 0px;
}
.dropbtn {
    padding: 16px;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    background: none;
    display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 0px;
}
/* .dropbtn:hover{
    background-color: #fff !important;
} */

.left_drop_item h3 {
    color: #565656;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 7px 10px;
    margin: 0px;
}

.dropdown {
    position: relative;
    padding: 28px 0px;
    display: flex;
    align-items: center;
}

.dropdown-content {
    /* display: none; */
    position: absolute;
    /* background-color: #f1f1f1; */
    min-width: 160px;
    overflow: auto;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    z-index: 1;
    width: 100%;
    top: 65px;
}

.dropdown-content a {
    padding: 7px 10px;
    text-decoration: none;
    display: block;
    color: #212121;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: lowercase;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
.open_drop_dv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown button {
    padding: 16px;
    border: none;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
    gap: 6px;
    justify-content: start;
    width: 100%;
}
.dropdown button ul{
    flex-wrap: wrap;
}
 ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    width: 100% !important;
    margin-left: 10px;
}
.dropdown span.right_icon svg {
    width: 14px;
    height: 14px;
    margin-left: auto;
    fill: #0284C7;
}
span.right_icon {
    margin-left: auto;
    cursor: pointer;
}
ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list button {
    border-radius: 22px;
    border: 1px solid rgba(45, 109, 167, 0.50);
    background: #BADAFF;
    padding: 4px 25px;
}
ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list button:nth-child(2){
    border-radius: 22px;
border: 1px solid rgba(158, 171, 6, 0.50);
background: #EEF993;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    box-shadow: none !important;
}
#myTextarea {
    /* width: 100%; */
    min-height: 200px;
    resize: none;
    border-radius: 7px;
    background: #FFF8F1;
    /* outline: 0px; */
    /* border: 0px; */
    /* padding: 10px; */
    width: 250px;
    z-index: 2;
}

/* .process_tabless .block.w-full.overflow-x-auto div {
  height: auto !important;
} */
.table-resposives{
  width: 100%;
}

.table-resposives thead {
  color: #fefefe;
}
.table-resposives tbody {
  width: 100%;
}
.table-resposives tbody td {
  background-color: #fefefe;
  padding: 10px;
}
.table-resposives tbody td::before {
  content: attr(aria-label);
  display: inline-block;
  font-weight: bold;
  margin: 0 1rem 0 0;
  min-width: 80px;
}
.table-resposives tr {
  max-width: 100%;
  width: 100%;
}
.Process_data-table {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
}
.table-resposives thead th {
  background-color: #0284c7;
  padding: 10px;
  text-align: left;
}
.profile_name_box{
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 600px) {
  .table-resposives tr {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .table-resposives {
    background-color: #efefef;
  }
  .table-resposives thead {
    display: table-header-group;
  }
  .table-resposives thead th {
    text-align: center;
  }
  .table-resposives tbody {
    display: table-row-group;
  }
  .table-resposives tbody td {
    background-color: transparent;
    display: table-cell;
    text-align: center;
    width: auto;
  }
  .table-resposives tbody td::before {
    display: none;
  }
  .table-resposives tbody td:first-child {
    background-color: transparent;
    color: inherit;
  }
  .table-resposives tr {
    display: table-row;
    margin: 0;
  }
  .table-resposives tr:nth-child(even) {
    background-color: #fefefe;
  }
  .table-resposives tr:nth-child(odd) {
    background-color: #efefef;
  }
}

@media screen and (max-width: 767px) {
    .profile_data_main_wrapper {
        width: 100%;
        padding-right: 0px;
        flex-wrap: wrap;
    }
    .profile_details_wrapper {
        width: 100%;
        align-items: center;
        padding: 0px;
    }
    .multiple_box_prfile {
        gap: 20px;
        padding: 20px 0px;
    }
    .profile_data_rgt_dv {
        width: 95%;
        padding: 0px;
    }
    .pro_box {
      width: 100%;
      justify-content: space-between;
      align-items: center;
  }
    .multiple_down_box_profile {
        flex-wrap: wrap;
        gap: 20px;
    }
    .dropdown_dv {
        width: 90%;
    }
    .img_box img {
        width: 50%;
        margin: 10px;
    }
    .img_box h2 {
        margin: 0px;
    } 
    .profile_data_rgt_dv_tp {
        width: 100%;
        padding: 0px;
    }
    .body_content_dv {
        margin-bottom: 15px;
    }
    .open_drop_dv {
      background: #fff;
  }
  .dropdown button {
    margin-bottom: 20px;
    font-size: 16px;
  font-weight: 700;
}
.dropdown span.right_icon svg {
  margin-bottom: 20px;
  
}
.img_box {
  width: 100%;
}
.left_drop_item h3 {
  font-size: 13px;
  padding: 6px 10px;
}
.top_head_dv h3 {
  font-size: 14px;
}
.top_head_dv button {
  font-size: 14px;
}
.top_head_dv {
  padding: 10px 0px;
}
.left_dtl h4 {
  color: #fff;
  font-size: 14px;
  padding: 6px 0px;
}
.right_dtl p {
  font-size: 12px;
  padding: 7px 0px;
}
}

@media only screen and (min-width: 768px) and (max-width: 1279px){
  .profile_details_wrapper {
    width: 100%;
    padding: 0px;
}
.profile_data_main_wrapper {
  width: 100%;
  flex-direction: column;
  padding: 0px;
}
.open_drop_dv {
  background: #fff;
}
.profile_data_rgt_dv_tp {
  width: 100%;
  padding: 0px;
}
.pro_box {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.multiple_down_box_profile {
  flex-wrap: wrap;
  padding: 10px 0px;
  gap: 20px;
}
.img_box {
  width: 40%;
}

}
@media only screen and (min-width: 1280px) and (max-width: 1680px){
  .profile_details_wrapper {
    width: 50%;
    padding: 0px;
}
.profile_data_rgt_dv_tp {
  width: 50%;
}
.pro_box {
  width: 100%;
}
.open_drop_dv {
  background: #fff;
}
.multiple_down_box_profile {
  padding:10px 0px;
  flex-direction: column;
  gap: 20px;
}
.img_box {
  width: 40%;
}
}