.login_page_back {
    /* background: #0284c7  !important; */  
    /* background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e) !important; */
    background-image:url("../assets/img/Login_page_bg.jpg")!important;
    background-position: 100%;
    /* background-image: url("../assets/img/robot-human-hands-moving-handshake.jpg") !important; */
}
body{
    margin: 0px !important;
}
.text-right.create_content a {
     color: #1e293b !important; 
    font-weight: 600;
    display: inline-block;
    text-align: left;
    width: 100%;
}
h6.text_b {
    color: #1e293b;
    font-size: 2.3em;
    
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    transition: .5s ease-in-out;
}
.E_label {
    font-size: 12px;
    font-weight: 700;
    padding: 2px 0px;
}
.shadow_lg_dv {
    background-image: linear-gradient(60deg, #3C6CFE, #7a00ff);
}
.main_shadow_dv{

    box-shadow: 5px 20px 50px #000 !important; 

}
.main_shadow_dv input{
    color: #475bff !important;
}
.register_page_dv {
    padding: 30px 0px;
    padding-bottom: 0px;
}
/* .bg-login-form{
    background-color: #eeeef30f;
    box-shadow: 2px 10px 20px 0px rgba(237, 235, 235, 0.38)
} */
.login_form_dv {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px 0px !important;
    padding-left: 0px !important;
}
.left_content-dv {
    width: 50%;
    text-align: center;
    height: 100%;
    background-color: hsla(0, 0%, 100%, 0.3);
    backdrop-filter: blur(30px);
    padding: 9rem 1rem;
    color: var(--text-dark);
    border-radius: 4px;
    border: 1px solid hsl(0 100% 100% / 0.3);
}
.left_content-dv h2 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 1rem;
}
.left_content-dv h2.uniq_c{
    webkit-text-fill-color: transparent;
}
.container.forget_pass {
    width: 33%;
    padding: 20px;
}
.flex-auto.login_form_dv {
    padding: 20px !important;
}
