  .search-box{
    margin:0px auto;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 30px;
  }
  .main_search_in {
    width: 423px;
    height: 46px;
    border-radius: 41px;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    margin: auto;
}
.main_search_in .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
    height: 100%;
}
.Search_left_dv{
    width: 80% !important;
}
.icons_dv {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 41px;
    background: #1A4C7A;
    /* padding: 0px 17px; */
}
.icons_dv button{
    border-radius: 41px;
    background: #1A4C7A;
    padding: 0px 12px;
    height: 100%;
}
.icons_dv button:hover{
    box-shadow: 0px 0px 2px #ccc;
}
.icons_dv button:focus{
    outline: 0px;
}
.icons_dv  svg{
    color: #fff;
}
.icons_dv .stand_brd{
    width: 2px;
    height: 100%;
    background-color: #fff;
}
.main_search_in fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0px;
    
}
.main_search_in label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    line-height: 16px;
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 35px;
}
.main_search_in input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputTypeSearch.css-1g24dm6-MuiInputBase-input-MuiOutlinedInput-input {
    height: 100%;
    padding: 14px 35px;
}
.main_search_in input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputTypeSearch.css-1g24dm6-MuiInputBase-input-MuiOutlinedInput-input:focus #outlined-search-label{
    display: none;
}
@media screen and (max-width: 767px) {
    .search-box {
        width: 100%;
    }
    .main_search_in {
        width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
    .search-box {
        width: 90%;
    }
}