.unorder_list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4%;
    padding: 25px;
    background-color: #f5f6f8;
}
.unorder_list .list{
    width: 20%;
    padding: 8px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.unorder_list .list_key{
    font-weight: 600;
}
/* .odd{
    background-color: #ddf1f8;
}
.even{
    background-color: #ffeddd;
} */
.master_data_dv .subprocess-table {
    margin: 0px !important;
    width: 100% !important;
}
.master_data_dv button {
    /* display: none; */
}