@import 'react-querybuilder/dist/query-builder.css';
/* @import '~bootstrap/scss/bootstrap'; */

body {
  margin: 0.5rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ruleGroup-addRule, .ruleGroup-addGroup, .button {
  display: inline-block;
  border-radius: 4px;
  background-color: #035319;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 17px;
  padding: 9px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.rule-remove {
  display: inline-block;
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 17px;
  padding: 9px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}


.input, .rule-value, .rule-value-list-item, .ruleGroup-combinators,.rule-operators, .rule-fields {
  height: 40px;
  border-radius: 8px;
  border: 2px solid hsl(236, 92%, 66%);
  outline: none;
  transition: 200ms ease-in;
  padding-left: 1rem;
}

span.rule-value {
  border: none;
}

.ruleGroup{
  padding-right: 47px;
  width: 100%;
}

.rule-value, .rule-value-list-item,.ruleGroup-combinators,.rule-fields,.rule-operators, .input:focus {
  border-bottom: 4px solid hsl(236, 92%, 66%);
  padding-top: 2px;
}

.rule-value, .rule-value-list-item, .ruleGroup-combinators,.rule-fields,.rule-operators, .input::placeholder {
  color: hsl(236, 92%, 66%);
  /* opacity: 0.5; */
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}
