.profileList{
    width: 12%;
    position: absolute;
    top: 78px;
    right: 58px;
    background-color: #1a4c7a;
    padding: 10px;
    border-radius: 10px;
    box-shadow:1px 6px 5px #036789;
}

.profileUnorderList{
    text-align: center;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    padding: 0 !important;
}

button.userProfileBtn:focus{
    border: 0px !important;
    outline: 0px !important;
}